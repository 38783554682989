module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-chakra-ui/gatsby-browser.js'),
      options: {"plugins":[],"isResettingCSS":true,"isUsingColorMode":true},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"thompsonfresh","accessToken":"MC5YdzdIMEJBQUFDVUFsX0Ry.Ou-_vS7vv70w77-9cisx77-9O23vv73vv73vv73vv73vv71_J--_vUjvv70E77-9Fu-_vWjvv73vv73vv71x77-9","path":"/preview","previews":true,"pages":[{"type":"Blog_post","match":"/blog/:uid","path":"/blog-preview","component":"/vercel/732c685f/src/page-templates/BlogPost.tsx"},{"type":"Market_report","match":"/market-guide/:uid","path":"/market-guide-preview","component":"/vercel/732c685f/src/page-templates/MarketPost.tsx"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-145106492-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Thompson Fresh","short_name":"thompson","start_url":"/","icons":[{"src":"/favicon/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicon/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"background_color":"#ffff","theme_color":"#4EB75D","display":"standalone","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
