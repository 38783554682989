import React from 'react';
import colors from './colors';
import sizes, { baseSizes } from './sizes';
import typography from './typography';
import chakraIcons from '@chakra-ui/core/dist/IconPaths';

const space = baseSizes;

const shadows = {
	sm: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
	md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
	lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
	xl:
		'0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
	'2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
	outline: '0 0 0 3px rgba(66, 153, 225, 0.6)',
	inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
	none: 'none'
};

const breakpoints = ['30em', '48em', '62em', '95em', '190em'];
// aliases
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];
breakpoints.xxl = breakpoints[4];

const zIndices = {
	hide: -1,
	auto: 'auto',
	base: 0,
	docked: 10,
	dropdown: 1000,
	sticky: 1100,
	banner: 1200,
	overlay: 1300,
	modal: 1400,
	popover: 1500,
	skipLink: 1600,
	toast: 1700,
	tooltip: 1800
};

const radii = {
	none: '0',
	sm: '0.125rem',
	md: '0.25rem',
	lg: '0.5rem',
	full: '9999px'
};

const opacity = {
	'0': '0',
	'20%': '0.2',
	'40%': '0.4',
	'60%': '0.6',
	'80%': '0.8',
	'100%': '1'
};

const borders = {
	none: 0,
	'1px': '1px solid',
	'2px': '2px solid',
	'4px': '4px solid'
};

// const borderWidths = {
//   xl: "2rem",
//   lg: "1rem",
//   md: "0.5rem",
//   sm: "0.25rem",
//   xs: "0.125rem",
//   "2xs": "0.0625rem",
//   none: 0
// };

const customIcons = {
	star: {
		path: (
			<svg xmlns="http://www.w3.org/2000/svg" width="14" height="16">
				<path
					fill="currentColor"
					fillRule="evenodd"
					d="M14 6l-4.9-.64L7 1 4.9 5.36 0 6l3.6 3.26L2.67 14 7 11.67 11.33 14l-.93-4.74L14 6z"
				/>
			</svg>
		),
		viewBox: '0 0 14 16'
	},
	github: {
		path: (
			<svg xmlns="http://www.w3.org/2000/svg">
				<path
					fill="#38D39F"
					d="M33.2 8.9a17.7 17.7 0 00-6.5-6.5 17.4 17.4 0 00-9-2.4A17.4 17.4 0 009 2.4a17.7 17.7 0 00-6.5 6.5A17.4 17.4 0 000 17.8a17.3 17.3 0 003.4 10.4 17.4 17.4 0 008.8 6.4 1 1 0 00.9-.1 1 1 0 00.3-.7v-3.3h-.6a6.8 6.8 0 01-1.3.2 9.8 9.8 0 01-1.6-.2 3.6 3.6 0 01-1.5-.7 3 3 0 01-1-1.4l-.3-.6a5.8 5.8 0 00-.7-1.1 2.8 2.8 0 00-1-1h-.2a1.7 1.7 0 01-.3-.3 1.3 1.3 0 01-.2-.3l.1-.3a1.5 1.5 0 01.7-.1H6a3.4 3.4 0 011.1.6 3.7 3.7 0 011.1 1.2A4 4 0 009.5 28a2.7 2.7 0 001.5.5 6.6 6.6 0 001.4-.1 4.6 4.6 0 001-.4 3.8 3.8 0 011.1-2.4 15.8 15.8 0 01-2.3-.4 9.4 9.4 0 01-2.2-.9 6.2 6.2 0 01-2-1.5 7.5 7.5 0 01-1-2.5 11.6 11.6 0 01-.5-3.4A6.8 6.8 0 018.3 12a6.2 6.2 0 01.1-4.7 3.2 3.2 0 012 .3 14 14 0 012 .9l1 .6a16.7 16.7 0 018.8 0l.9-.6a12.5 12.5 0 012.1-1 3 3 0 011.9-.2 6.2 6.2 0 01.2 4.7 6.8 6.8 0 011.8 4.8 11.7 11.7 0 01-.5 3.4 7.2 7.2 0 01-1.2 2.5 6.5 6.5 0 01-1.9 1.5 9.5 9.5 0 01-2.1.9 15.8 15.8 0 01-2.4.4 4.1 4.1 0 011.2 3.3v4.9a1 1 0 00.3.7 1 1 0 00.9.1 17.4 17.4 0 008.8-6.4 17.3 17.3 0 003.3-10.4 17.4 17.4 0 00-2.3-9z"
					data-name="Path 291"
				/>
			</svg>
		),
		viewBox: '0 0 35.5 34.7'
	},
	instagram: {
		path: (
			<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="#4eb75d"
					d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913a5.885 5.885 0 001.384 2.126A5.868 5.868 0 004.14 23.37c.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558a5.898 5.898 0 002.126-1.384 5.86 5.86 0 001.384-2.126c.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913a5.89 5.89 0 00-1.384-2.126A5.847 5.847 0 0019.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227a3.81 3.81 0 01-.899 1.382 3.744 3.744 0 01-1.38.896c-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421a3.716 3.716 0 01-1.379-.899 3.644 3.644 0 01-.9-1.38c-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678a6.162 6.162 0 100 12.324 6.162 6.162 0 100-12.324zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405a1.441 1.441 0 01-2.88 0 1.44 1.44 0 012.88 0z" />
			</svg>
		),
		viewBox: '0 0 35.5 34.7'
	},
	facebook: {
		path: (
			<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
				<path
					fill="#4eb75d"
					d="M23.998 12c0-6.628-5.372-12-11.999-12C5.372 0 0 5.372 0 12c0 5.988 4.388 10.952 10.124 11.852v-8.384H7.078v-3.469h3.046V9.356c0-3.008 1.792-4.669 4.532-4.669 1.313 0 2.686.234 2.686.234v2.953H15.83c-1.49 0-1.955.925-1.955 1.874V12h3.328l-.532 3.469h-2.796v8.384c5.736-.9 10.124-5.864 10.124-11.853z"
				/></svg>
		),
		viewBox: '0 0 35.5 34.7'
	},
	linkedin: {
		path: (
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
				<path
					fill="#38D39F"
					d="M19 0H5a5 5 0 00-5 5v14a5 5 0 005 5h14a5 5 0 005-5V5a5 5 0 00-5-5zM8 19H5V8h3v11zM6.5 6.732c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zM20 19h-3v-5.604c0-3.368-4-3.113-4 0V19h-3V8h3v1.765c1.396-2.586 7-2.777 7 2.476V19z"
				/>
			</svg>
		),
		viewBox: '0 0 24 24'
	},
	checkLine: {
		path: (
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" stroke-width="2"><g
				fill="none"
				stroke="#38D39F"
				stroke-miterlimit="10">
				<path data-cap="butt" d="M20.7 9.4c.2.8.3 1.7.3 2.6 0 5.5-4.5 10-10 10S1 17.5 1 12 5.5 2 11 2c1.9 0 3.7.5 5.3 1.5" />
				<path data-color="color-2" stroke-linecap="square" d="M7 10l4 4L22 3" /></g>
			</svg>),
		viewBox: '0 0 24 24'
	},
	shareLink: {
		path: (
			<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48"><g fill="#4eb75d"><path data-color="color-2" d="M28.934 4.925L19.757 14.1a9.989 9.989 0 003.423 16.377l.82-.82a3.942 3.942 0 001.148-2.728 6 6 0 01-2.562-10l9.177-9.176a6 6 0 018.485 8.485l-7.5 7.5a12.069 12.069 0 01.2 5.336 9.958 9.958 0 00.958-.832l9.177-9.176A10.002 10.002 0 0028.934 4.925z" /><path d="M19.066 43.075l9.177-9.175a9.989 9.989 0 00-3.423-16.377l-.82.82a3.942 3.942 0 00-1.148 2.728 6 6 0 012.562 10l-9.177 9.176a6 6 0 01-8.485-8.485l7.5-7.5a12.069 12.069 0 01-.2-5.336 9.958 9.958 0 00-.958.832l-9.17 9.175a10 10 0 0014.142 14.142z" /></g></svg>),
		viewBox: '0 0 48 48'
	}
};

const theme = {
	breakpoints,
	zIndices,
	radii,
	opacity,
	borders,
	colors,
	...typography,
	sizes,
	shadows,
	space,
	icons: {
		...chakraIcons,
		...customIcons
	}
};

export default theme;
